<!-- 任务列表 -->
<template>
  <div class="vhc-container">
    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar" v-if="!isEmpty" style="margin-top: 20px">
      <vh-button size="medium" type="primary" round @click="handleAdd">创建</vh-button>
      <vh-button
        size="medium"
        round
        plain
        @click="handleDelete()"
        :disabled="!multipleSelection.length"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        size="medium"
        class="search-input"
        placeholder="请输入标题"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
        @blur="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd" v-loading="!isInited && loading">
      <div class="vhc-main" v-if="isInited">
        <!-- 没有数据时的展示 -->
        <div class="no-content" v-if="isEmpty">
          <noData :nullType="'nullData'" class="no_data" :text="'您还没有内容，快来创建吧~'">
            <vh-button
              type="primary"
              round
              borderRadius="50"
              size="medium"
              style="width: 152px"
              v-preventReClick
              @click="handleAdd"
            >
              创建
            </vh-button>
          </noData>
        </div>
        <!-- 数据列表 -->
        <div class="vhc-main-content" v-if="!isEmpty">
          <vh-table
            ref="tableList"
            :data="dataList"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            @cell-mouse-enter="handleCellMouseEnter"
            @cell-mouse-leave="handleCellMouseLeave"
            class="vhc-main-table"
          >
            <vh-table-column
              type="selection"
              width="60"
              fixed="left"
              :selectable="
                row => {
                  return row.state == 0;
                }
              "
            />
            <vh-table-column prop="title" label="标题" min-width="160">
              <template slot-scope="scope">
                <vh-tooltip placement="top" :disabled="!isTextOverflow" :content="scope.row.title">
                  <div class="custom-tooltip-content">
                    {{ scope.row.title }}
                  </div>
                </vh-tooltip>
              </template>
            </vh-table-column>
            <vh-table-column prop="survey_name" min-width="120" label="参与条件">
              <template slot-scope="scope">
                <div class="custom-tooltip-content">
                  {{ `观看${scope.row.watch_minute}分钟` || '-' }}
                </div>
              </template>
            </vh-table-column>
            <vh-table-column prop="award_number" label="礼品数量" width="120"></vh-table-column>
            <vh-table-column label="中奖人数" width="100">
              <template slot-scope="scope">
                <vh-button type="text" @click="handleUserList(scope.row)">
                  {{ scope.row.winners_number }}
                </vh-button>
              </template>
            </vh-table-column>
            <vh-table-column label="状态" min-width="100">
              <template slot-scope="scope">
                {{ sateMap[scope.row.state] }}
              </template>
            </vh-table-column>
            <vh-table-column prop="updated_at" label="最后操作" width="180"></vh-table-column>
            <vh-table-column label="操作" width="148" fixed="right">
              <template slot-scope="scope">
                <vh-button type="text" size="tiny" @click="handleCopy(scope.row)">复制</vh-button>
                <vh-button
                  type="text"
                  :plain="scope.row.state != 0"
                  :disabled="scope.row.state != 0"
                  size="tiny"
                  @click="handleEdit(scope.row)"
                >
                  编辑
                </vh-button>
                <vh-button
                  :plain="scope.row.state != 0"
                  :disabled="scope.row.state != 0"
                  type="text"
                  size="tiny"
                  @click="handleDelete(scope.row)"
                >
                  删除
                </vh-button>
              </template>
            </vh-table-column>
            <template slot="empty">
              <noData
                :nullType="'search'"
                class="no_data"
                :text="'暂未搜索到您想要的内容'"
              ></noData>
            </template>
          </vh-table>
          <SPagination
            :total="total || 0"
            :page-size="pagination.limit"
            :currentPage="pagination.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';

  export default {
    name: 'InteractiveRewardTaskList',
    mixins: [tableCellTooltip],
    components: {
      noData
    },
    data() {
      return {
        isEmbed: isEmbed(),
        webinarId: '', //活动id

        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据

        total: 0,
        keyword: '',
        multipleSelection: [],
        dataList: [],
        // 分页参数
        pagination: {
          pos: 0,
          limit: 10,
          pageNo: 1
        },
        sateMap: {
          0: '未推送',
          1: '推送中',
          2: '已结束'
        }
      };
    },
    computed: {
      settingPath() {
        return `${isEmbed() ? '/embed' : ''}/live/WatchRewardSetting/${this.webinarId}`;
      },
      userListPath() {
        return `${isEmbed() ? '/embed' : ''}/live/WatchRewardWinnerList/${this.webinarId}`;
      }
    },
    methods: {
      // 创建
      handleAdd() {
        if (this.total >= 1000) {
          this.$vhMessage.info('最多可以创建1000个');
          return;
        }
        this.$router.push({
          path: this.settingPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId
          }
        });
      },
      // 编辑某一条
      handleEdit(row) {
        this.$router.push({
          path: this.settingPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            wrId: row.id
          }
        });
      },
      // 复制某一条
      handleCopy(row) {
        if (this.total >= 1000) {
          this.$vhMessage.info('最多可以创建1000个');
          return;
        }
        this.$fetch('copyWatchReward', {
          webinar_id: this.webinarId,
          id: row.id
        }).then(res => {
          if (res.code == 200) {
            this.$message.success('复制成功');
            this.fetchData();
          }
        });
      },
      // 领奖用户列表
      handleUserList(row) {
        console.error(row);
        this.$router.push({
          path: this.userListPath,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            id: row.id
          }
        });
      },
      // 删除某一条/批量删除
      async handleDelete(row) {
        const confirm = await this.$vhConfirm('删除后，当前内容无法使用，确认删除？', '提示', {
          roundButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,

          closeOnClickModal: false,
          closeOnPressEscape: false,
          cancelButtonClass: 'zdy-confirm-cancel'
        }).catch(() => false);
        if (!confirm) return;
        const params = {
          webinar_id: this.webinarId,
          ids: row ? row.id + '' : (this.multipleSelection.map(item => item.id) || []).join(',')
        };
        const [err, res] = await to(this.$fetch('delWatchReward', params));
        if (err || res?.code != 200) {
          this.$vhMessage.error(err?.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success('删除成功');
        this.fetchData();
      },

      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.pagination.pageNo = 1;
        this.fetchData();
      },
      // 全选/部分选中/取消/部分取消时触发
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },

      // 获取观看有礼列表数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        const params = {
          webinar_id: this.webinarId,
          keyword: encodeURI(this.keyword),
          limit: this.pagination.limit,
          pos: this.pagination.pos
        };
        let [err, res] = await to(this.$fetch('getWatchRewardList', params));
        this.loading = false;
        // if (err || res?.code != 200) {
        //   this.$vhMessage.error(err?.msg || res?.msg || '请求数据失败');
        //   return;
        // }
        this.isInited = true;
        this.total = res.data.total || 0;
        this.isEmpty = !this.total && this.keyword.length === 0;
        this.dataList = res.data.list || [];
        if (this.dataList.length == 0 && res.data.total > 0) {
          this.handleCurrentChange(this.pagination.pageNo - 1);
        }
      },
      //复选框禁用逻辑处理
      selectable(row, rowIndex) {
        return !(row.recipients_number > 0);
      }
    },
    mounted() {
      this.webinarId = this.$route.params.str || '';
      this.fetchData();
    }
  };
</script>
<style lang="less" scoped>
  .vhc-container__bd {
    min-height: 500px !important;
    /deep/.vh-button--text.is-plain.is-disabled:hover {
      color: rgb(191, 191, 191) !important;
    }
    /deep/ .vhc-main-table::before {
      height: 0;
    }
    /deep/ .vh-table__fixed::before {
      height: 0;
    }
    /deep/ .vh-table__fixed-right::before {
      height: 0;
    }
  }
  .no_data {
    height: 620px;
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-content {
    background: #f7f7f7;
  }
</style>
