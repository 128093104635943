<template>
  <div class="vhc-container">
    <!-- 标题栏 -->
    <pageTitle pageTitle="观看有礼">
      <div slot="content">
        1.直播中达到指定观看时长后，将有机会获得礼品；观看回放不支持
        <br />
        2.观看时长定义：观众在直播间的连续时长，观众退出直播间重新进入或刷新直播间，观看时长将重新统计
        <br />
        3.同一时间，只能存在一个抽奖，抽奖结束可再次发起抽奖
        <br />
        4.领奖数据更新频率：10秒，可在活动结束后查看完整数据
      </div>
    </pageTitle>

    <TaskList></TaskList>

    <begin-play :webinarId="$route.params.str"></begin-play>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import TaskList from './TaskList.vue';
  // import RewardList from './RewardList.vue';
  import beginPlay from '@/components/beginBtn';

  export default {
    name: 'InteractReward',
    components: {
      PageTitle,
      TaskList,
      // RewardList,
      beginPlay
    },
    data() {
      return {};
    },
    methods: {
      handleTabClick() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            tab: this.activeTab
          }
        });
      }
    },
    created() {}
  };
</script>
<style lang="less">
  .interactive-prize-tabs {
    background-color: #fff;

    .vh-tabs__header {
      margin: 0;
      // padding: 0;
      .vh-tabs__nav-wrap {
        padding-right: 20px;
        &::after {
          background-color: #e6e6e6;
        }
      }
    }
    .vh-tabs__item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
    .vh-tabs__content {
      // padding: 0;
    }
  }
</style>
